import type { ComponentRef } from '@wix/platform-editor-sdk';
import type { FlowEditorSDK, TFunction } from '@wix/yoshi-flow-editor';
import type { FedopsLogger } from 'root/utils/monitoring/FedopsLogger';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';
import { MENUS_SHOWCASE_APP_DEF_ID, SETTINGS_PANEL_ARTICLE_ID } from 'root/utils/consts';

export const openMenuSettingsPanel = async (
  editorSDK: FlowEditorSDK,
  componentRef: ComponentRef,
  fedopsLogger: FedopsLogger,
  t: TFunction
) => {
  fedopsLogger.openMenuSettingsPanelStarted();
  await editorSDK.editor.openComponentPanel(MENUS_SHOWCASE_APP_DEF_ID, {
    title: t('menu-settings-panel.title'),
    url: getPanelUrl('Menu', 'MenuSettings'),
    width: 288,
    height: 576,
    componentRef,
    initialData: {
      componentRef,
    },
    helpId: SETTINGS_PANEL_ARTICLE_ID,
  });
};

export const openSettingsPanel = async (
  editorSDK: FlowEditorSDK,
  componentRef: ComponentRef,
  fedopsLogger: FedopsLogger,
  t: TFunction
) => {
  fedopsLogger.openSettingsPanelStarted();
  await editorSDK.editor.openComponentPanel(MENUS_SHOWCASE_APP_DEF_ID, {
    title: t('settings-panel.title'),
    url: getPanelUrl('Menu', 'Settings'),
    width: 288,
    height: 576,
    componentRef,
    initialData: {
      componentRef,
    },
    type: editorSDK.editor.PanelType.Settings,
    helpId: SETTINGS_PANEL_ARTICLE_ID,
  });
};

export const openMigratePanel = async (
  editorSDK: FlowEditorSDK,
  componentRef: ComponentRef,
  fedopsLogger: FedopsLogger,
  t: TFunction
) => {
  await editorSDK.editor.openComponentPanel(MENUS_SHOWCASE_APP_DEF_ID, {
    title: t('upgrade-panel.title'),
    url: getPanelUrl('Menu', 'Migrate'),
    width: 288,
    height: 240, // TODO: return to 297 when KB is ready
    componentRef,
    initialData: {
      componentRef,
    },
    // helpId: UPGRADE_PANEL_ARTICLE_ID, //TODO: uncomment when KB is ready
  });
};
