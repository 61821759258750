import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import {
  PARENT_APP_DEF_ID,
  PAGE_DATA,
  MENUS_SHOWCASE_APP_DEF_ID,
  OLO_APP_DEF_ID,
  OPTIMUS_ORIGIN_TYPE,
  UPGRADE_PANEL_ARTICLE_ID,
} from 'root/utils/consts';
import { createAppPage, setPagesState } from 'root/utils/createPage';
import type {
  EditorReadyFn,
  FlowEditorSDK,
  GetAppManifestFn,
  OwnerLogger,
  TFunction,
  EditorScriptFlowAPI,
} from '@wix/yoshi-flow-editor';
import { EditorType, PanelResolveType } from '@wix/platform-editor-sdk';
import {
  type Exports,
  type AppManifest,
  type InitialAppData,
  type AppExposedApis,
  type EditorReadyOptions,
} from '@wix/platform-editor-sdk';
import { FedopsLogger } from './utils/monitoring/FedopsLogger';
import {
  menusEditorMenuSettingsPanelAction,
  menusEditorShowHidePlaceholderImage,
  menusEditorPlaceholderImageDesignAction,
} from '@wix/bi-logger-restaurants/v2';
import {
  menusInstallationSrc159Evid170,
  menusUpgradeToOoiClickSrc159Evid171,
} from '@wix/bi-logger-restaurants-data/v2';
import type {
  menusEditorMenuSettingsPanelActionParams,
  menusEditorShowHidePlaceholderImageParams,
  menusEditorPlaceholderImageDesignActionParams,
} from '@wix/bi-logger-restaurants/v2/types';
import { initGfppEventListener } from './editor.app.services/gfppEventListener';
import { setMenusMobileGfpp, setMenusPresetsData } from './utils/manifestUtils';
import { initPageActionsEventListener } from './editor.app.services/pageActionsEventListener';
import type { AddPageProgressBarTexts } from './components/Menu/panels/MenuSettings/types';
import { initPresetChangedEventListener } from './editor.app.services/presetChangedEventListener';
import { BiReporter } from './utils/BiReporter';
import { MENU_LAYOUT_ORIGIN, MENU_PRESETS_PANEL } from './utils/bi';
import { isMobileViewport, getIsStudio } from './utils/commonUtils';
import { initDeviceSwitchEventListener } from './editor.app.services/deviceSwitchEventListener';
import { configureMyBusinessActions } from './editor.app.services/configureMyBusinessActions';
import { createAdditionalMenuPage, privateOOIExports } from './utils/OOI/editorScript/exports/private';
import { configureCommonGfpp } from './utils/OOI/editorScript/appManifest/manifest';
import { WIDGET_IDS } from './utils/OOI/consts';
import { migrateAll } from './utils/migration/migration';
import { createMenuOOIPage } from './utils/OOI/editorScript/shared/createPage';
import { setPagesState as setOOIPagesState } from './utils/OOI/editorScript/shared/setPagesState';
import { baseUrlFromEditorUrl } from './utils/url';
import { InstallationFlow } from './utils/OOI/bi';

const TOKEN = 'token';
let flowAPI: EditorScriptFlowAPI;

export const editorReady: EditorReadyFn = async (editorSDK, appDefId, options, _flowAPI) => {
  flowAPI = _flowAPI;
  const t = flowAPI.translations.t as TFunction;
  const fedopsLogger = new FedopsLogger(flowAPI.fedops);
  const editorType = options.origin.type;
  const isUseMenusOOIExperimentEnabled = flowAPI.experiments.enabled('specs.restaurants.useMenusOOIWidget');
  const biLogger = options.essentials.biLoggerFactory().logger();
  const msid = await editorSDK.document.info.getMetaSiteId('');

  if (options.firstInstall) {
    biLogger.report(menusInstallationSrc159Evid170({ isOoi: isUseMenusOOIExperimentEnabled, msid }));
    try {
      const isParentAppInstalled = await editorSDK.document.application.isApplicationInstalled(TOKEN, {
        appDefinitionId: PARENT_APP_DEF_ID,
      });
      if (!isParentAppInstalled) {
        await editorSDK.document.application.add(TOKEN, {
          appDefinitionId: PARENT_APP_DEF_ID,
        });
      }
    } catch (err) {
      const error = typeof err === 'string' ? new Error(err) : (err as Error);
      flowAPI.reportError(error);
    }

    fedopsLogger.menusShowcaseFirstInstallStarted();
    // Hotfix for fake progress bar is open error - should investigate
    let isOpenProgressBarSuccess = true;
    const isResponsive = editorType === EditorType.Responsive;

    if (isUseMenusOOIExperimentEnabled) {
      const shouldNavigateToPage = options.origin.info?.appDefinitionId !== OLO_APP_DEF_ID;
      await editorSDK.document.transactions.runAndWaitForApproval('token', async () => {
        await createMenuOOIPage({
          editorSDK,
          t,
          tpaAppId: options.initialAppData.applicationId,
          isResponsive,
          shouldNavigateToPage,
        });
      });
      return;
    }
    const shouldOpenProgressBar = options.origin.info?.appDefinitionId !== OLO_APP_DEF_ID && !isResponsive;

    if (shouldOpenProgressBar) {
      try {
        await editorSDK.editor.openProgressBar(TOKEN, {
          title: t('app.loader.label'),
          totalSteps: 1,
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('openProgressBar failure', e);
        isOpenProgressBarSuccess = false;
      }
    }

    const isOptimusOrigin = (options.origin.info?.type as unknown) === OPTIMUS_ORIGIN_TYPE;

    const { widgetRef, pageRef } = await createAppPage({
      editorSDK,
      appDefId,
      pageData: PAGE_DATA,
      pageTitle: t(PAGE_DATA.title),
      isResponsive,
      shouldAddMenuItem: true,
      shouldNavigateToPage: isResponsive,
      hidePage: isOptimusOrigin,
    });
    if (shouldOpenProgressBar) {
      try {
        await editorSDK?.editor.updateProgressBar('', {
          currentStep: 1,
          stepTitle: t('app.completed.label'),
        });
      } catch (e) {
        flowAPI.reportError(e as Error);
      }
    }
    fedopsLogger.menusShowcaseFirstInstallEnded();

    if (isOpenProgressBarSuccess && shouldOpenProgressBar) {
      try {
        await editorSDK.document.pages.navigateTo(TOKEN, { pageRef });
        widgetRef && (await editorSDK.editor.selection.selectComponentByCompRef(TOKEN, { compsToSelect: [widgetRef] }));

        await editorSDK.editor.closeProgressBar(TOKEN, {});
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('closeProgressBar failure', e);
      }
    }
  }
  const isStudio = getIsStudio(options);
  await initGfppEventListener({ flowAPI, editorSDK, fedopsLogger, editorType });
  await initPageActionsEventListener({ editorSDK, flowAPI });
  await initPresetChangedEventListener({ editorSDK, flowAPI });
  isStudio && (await initDeviceSwitchEventListener({ editorSDK }));

  await setPagesState({ editorSDK });
  await setOOIPagesState({ editorSDK });
};

export const getAppManifest: GetAppManifestFn = async (options, editorSDK, context, _flowAPI): Promise<AppManifest> => {
  const { appManifestBuilder, ...appData } = options;
  const baseManifest = await fetchWidgetsStageData(appData as InitialAppData);
  const { translations } = _flowAPI;
  const t = translations.t as TFunction;
  const isStudio = getIsStudio(context);

  appManifestBuilder.configureManagementActions((managementActionsBuilder) => {
    configureMyBusinessActions(editorSDK, flowAPI, managementActionsBuilder);
  });

  const isMenusBlocksLastTimeExperienceEnabled = _flowAPI.experiments.enabled(
    'specs.restaurants.menusBlocksLastTimeExperience'
  );

  const manifest = appManifestBuilder
    .configureWidget(WIDGET_IDS.menus, (widgetBuilder) => {
      configureCommonGfpp(widgetBuilder, t, editorSDK, flowAPI);
      widgetBuilder.set({ displayName: t('widget.menu.display-name') });
    })
    .withJsonManifest(baseManifest)
    .build();

  !isMenusBlocksLastTimeExperienceEnabled && setMenusMobileGfpp(manifest);

  const isMobile = await isMobileViewport(editorSDK);
  !isMenusBlocksLastTimeExperienceEnabled && setMenusPresetsData(manifest, isMobile, isStudio, t);

  return manifest;
};

export const exports: Exports = (editorSDK: FlowEditorSDK, context: EditorReadyOptions): AppExposedApis => {
  const biLogger = context.essentials.biLoggerFactory().logger();
  const fedopsLogger = new FedopsLogger(context.essentials.fedopsLogger);
  const editorType = context.origin.type;
  const isResponsive = editorType === EditorType.Responsive;
  const baseUrl = baseUrlFromEditorUrl(context.initialAppData.editorScriptUrl);

  return {
    public: {
      uninstall: () => editorSDK.application.uninstall('', { openConfirmation: true }), // TODO: https://jira.wixpress.com/browse/RST-6288
    },
    private: {
      ...privateOOIExports(editorSDK),
      reportMenuSettingsBi: (params: menusEditorMenuSettingsPanelActionParams) =>
        biLogger.report(menusEditorMenuSettingsPanelAction(params)),
      reportMenuPlaceholderImageBi: (params: menusEditorShowHidePlaceholderImageParams) =>
        biLogger.report(menusEditorShowHidePlaceholderImage(params)),
      reportMenuPlaceholderImageDesignBi: (params: menusEditorPlaceholderImageDesignActionParams) =>
        biLogger.report(menusEditorPlaceholderImageDesignAction(params)),
      createMenuPage: async () => {
        const t = flowAPI.translations.t as TFunction;
        const msid = await editorSDK.document.info.getMetaSiteId('');
        const isUseMenusOOIExperimentEnabled = flowAPI.experiments.enabled('specs.restaurants.useMenusOOIWidget');
        if (isUseMenusOOIExperimentEnabled) {
          await createAdditionalMenuPage({
            editorSDK,
            t,
            tpaAppId: context.initialAppData.applicationId,
            isResponsive,
          });
          biLogger.report(menusInstallationSrc159Evid170({ isOoi: true, msid, flow: InstallationFlow.AddPage }));
          return;
        }
        const progressBarTexts: AddPageProgressBarTexts = {
          title: t('settings-panel.add-page.progress-bar.title'),
          inProgress: t('settings-panel.add-page.progress-bar.in-progress'),
          completed: t('settings-panel.add-page.progress-bar.completed'),
        };
        await editorSDK?.editor.openProgressBar('', {
          title: progressBarTexts.title,
          stepTitle: progressBarTexts.inProgress,
          totalSteps: 1,
        });

        const pageTitle = t(PAGE_DATA.title);
        const { pageRef } = await createAppPage({
          editorSDK,
          appDefId: MENUS_SHOWCASE_APP_DEF_ID,
          pageData: PAGE_DATA,
          pageTitle,
          isResponsive,
          shouldAddMenuItem: true,
          shouldNavigateToPage: true,
        });

        await editorSDK?.editor.updateProgressBar('', {
          currentStep: 1,
          stepTitle: progressBarTexts.completed,
        });
        await editorSDK?.editor.closeProgressBar('', {});
        // @ts-expect-error
        await editorSDK?.editor.openPagesPanel('', {
          pageRef,
          renameEnabled: true,
        });
      },
      migrateToOoi: async () => {
        const t = flowAPI.translations.t as TFunction;
        const msid = await editorSDK.document.info.getMetaSiteId('');

        try {
          await editorSDK?.editor.openProgressBar('', {
            title: t('upgrade-panel.progress-bar.title'),
            stepTitle: t('upgrade-panel.progress-bar.in-progress'),
            totalSteps: 10,
            currentStep: 5,
          });

          await migrateAll({ editorSDK, tpaAppId: context.initialAppData.applicationId, isResponsive, flowAPI });
          biLogger.report(menusUpgradeToOoiClickSrc159Evid171({ isSuccessful: true, msid }));

          await editorSDK?.editor.updateProgressBar('', {
            currentStep: 10,
          });

          await editorSDK?.editor.closeProgressBar('', {});
          const resolveType = await editorSDK?.editor.openConfirmationPanel('', {
            headerText: t('upgrade-panel.confirmation.title'),
            descriptionText: t('upgrade-panel.confirmation.description'),
            mainActionText: t('upgrade-panel.confirmation.main-cta.text'),
            secondaryActionText: t('upgrade-panel.confirmation.secondary-cta.text'),
            shouldShowIllustration: true,
            helpId: UPGRADE_PANEL_ARTICLE_ID,
            illustration: `${baseUrl}assets/icons/upgrade-confirmation.svg`,
          });

          biLogger.report(
            menusInstallationSrc159Evid170({ isOoi: true, isSuccessful: true, msid, flow: InstallationFlow.Upgrade })
          );
          if (resolveType === PanelResolveType.SECONDARY_ACTION) {
            editorSDK?.editor.openHelpPanel('', {
              helpId: UPGRADE_PANEL_ARTICLE_ID,
            });
          }
        } catch (e) {
          biLogger.report(menusUpgradeToOoiClickSrc159Evid171({ isSuccessful: false }));
          biLogger.report(
            menusInstallationSrc159Evid170({ isOoi: true, isSuccessful: false, msid, flow: InstallationFlow.Upgrade })
          );
          await editorSDK?.editor.closeProgressBar('', {});
        }
      },
      reportFedopsOpenMenuSettingsPanelEnded: fedopsLogger.openMenuSettingsPanelEnded,
      reportFedopsOpenSettingsPanelEnded: fedopsLogger.openSettingsPanelEnded,
    },
    editor: {
      getSelectedWidgetDesignPresetId: async ({ widgetRef }) => {
        const biReporter = new BiReporter(biLogger as OwnerLogger, widgetRef.id);
        await biReporter.init({ editorSDK });
        biReporter.reportMenuWidgetPanelBi(MENU_LAYOUT_ORIGIN, MENU_PRESETS_PANEL);
        return (
          await editorSDK.application.appStudioWidgets.getPreset('', {
            componentRef: widgetRef,
          })
        ).style;
      },
    },
  };
};
