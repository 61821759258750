import React from 'react';
import type { FC } from 'react';
import type { ILocation, WidgetProps } from '@wix/yoshi-flow-editor';
import { Menu } from 'root/components/Menus/Menu/Menu';
import type { PopulatedMenu } from 'root/apiTypes';
import { dataHooks } from 'root/dataHooks';
import type { NavigateToFn } from 'root/clientTypes';
import { useMenusLogic } from './Widget.logic';
import { AppWrapper } from 'root/components/Menus/AppWrapper/AppWrapper';
import { classes } from './Widget.st.css';
import { Navigation } from 'root/components/Menus/Navigation/Navigation';
import { MenuState } from 'root/components/Menus/MenuState/MenuState';
import { SideImage } from 'root/components/Menus/SideImage/SideImage';

export type ControllerProps = {
  menus: PopulatedMenu[];
  navigateTo: NavigateToFn;
  error?: Error;
  url: ILocation['url'];
  fitToContentHeight: true;
  initialActiveTab: number;
};

export const Widget: FC<WidgetProps<ControllerProps>> = ({ menus, navigateTo, error, url, initialActiveTab }) => {
  const { activeTab, displayableMenus, shouldShowTabs, setActiveTab } = useMenusLogic({
    menus,
    initialActiveTab,
  });

  if (error) {
    return (
      <AppWrapper>
        <MenuState content="component.menu.error-state" />
      </AppWrapper>
    );
  }

  return (
    <AppWrapper>
      {displayableMenus?.length > 0 ? (
        <div className={classes.root} data-hook={dataHooks.menus.container}>
          <div className={classes.menuContainer}>
            <SideImage mobileImage={false} />
            <div className={classes.menuContent}>
              {shouldShowTabs && (
                <Navigation
                  activeItem={activeTab}
                  currentUrl={url}
                  menus={displayableMenus}
                  navigateTo={navigateTo}
                  setActiveItem={setActiveTab}
                />
              )}
              <SideImage mobileImage />
              <Menu menu={displayableMenus[activeTab]} />
            </div>
          </div>
        </div>
      ) : (
        <MenuState content="component.menu.empty-state" />
      )}
    </AppWrapper>
  );
};
